import Ambiance from '@/assets/sounds/soundsEffect/ambiance.mp3';
import styles from '@/assets/styles/app.module.scss';
import CloudsBackground from '@/features/devices/tv/components/cloudsBackground/CloudsBackground';
import Game4 from '@/features/devices/tv/components/game4/Game4';
import HeaderTv from '@/features/devices/tv/components/header/Header';
import Historical from '@/features/devices/tv/components/historical/Historical';
import MainComponent from '@/features/devices/tv/components/mainComponent/MainComponent';
import Overlay from '@/features/devices/tv/components/overlay/Overlay';
import WelcomeScreen from '@/features/devices/tv/components/welcome/WelcomeScreen';
import useCurrentOverlay from '@/hooks/useCurrentOverlay';
import useReSynchronizeDevice from '@/hooks/useReSynchronizeDevice';
import { emitSocketEvent } from '@/services/global/globalUtils';
import { useGameStore } from '@/store/gameStore';
import { useEffect, useState } from 'react';
import { GAMESTATUS, OverlayType } from '@/types/global/types';

const Tv: React.FC = () => {
    const { overlayType, isCurrentOverlay } = useCurrentOverlay();
    const [isFirstRender, setIsFirstRender] = useState(true);

    useReSynchronizeDevice('tv');

    const currentGameStatus = useGameStore.use.gameStatus();

    useEffect(() => {
        emitSocketEvent('send_synchronize_device', {
            deviceColor: 'tv',
        });
    }, []);

    useEffect(() => {
        document.title = '🖥️ TV';

        if (currentGameStatus === GAMESTATUS.WELCOME_SCREEN) return;
        if (!isFirstRender) return;

        const ambianceSound = new Audio(Ambiance);
        ambianceSound.loop = true;
        ambianceSound.volume = 0.2;
        ambianceSound.play();
        setIsFirstRender(false);
    }, [currentGameStatus, isFirstRender]);

    const handleRender = () => {
        switch (currentGameStatus) {
            case GAMESTATUS.WELCOME_SCREEN:
                return <WelcomeScreen />;
            case GAMESTATUS.GAME1ACTION:
            case GAMESTATUS.GAME2ACTION:
            case GAMESTATUS.GAME3ACTION:
                return (
                    <>
                        {isCurrentOverlay ? (
                            <Overlay />
                        ) : (
                            <>
                                <HeaderTv />
                                <Historical />
                                <MainComponent />
                            </>
                        )}
                    </>
                );
            case GAMESTATUS.GAME4ACTION:
                return (
                    <>
                        {isCurrentOverlay ? (
                            <Overlay />
                        ) : (
                            <>
                                <HeaderTv />
                                <Game4 />
                            </>
                        )}
                    </>
                );
            case GAMESTATUS.GAME1UNLOCK:
            case GAMESTATUS.GAME2UNLOCK:
            case GAMESTATUS.GAME3UNLOCK:
            case GAMESTATUS.GAME4UNLOCK:
                return <Overlay type={OverlayType.UNLOCK} />;
            case GAMESTATUS.INTRODUCTION:
            case GAMESTATUS.GAME1INTRODUCTION:
            case GAMESTATUS.GAME2INTRODUCTION:
            case GAMESTATUS.GAME3INTRODUCTION:
            case GAMESTATUS.GAME4INTRODUCTION:
            case GAMESTATUS.GAME1PREVENTION:
            case GAMESTATUS.GAME2PREVENTION:
            case GAMESTATUS.GAME3PREVENTION:
            case GAMESTATUS.GAME4PREVENTION:
            case GAMESTATUS.GAME1SCORE:
            case GAMESTATUS.GAME2SCORE:
            case GAMESTATUS.GAME3SCORE:
            case GAMESTATUS.GAME4SCORE:
                return <Overlay type={OverlayType.NEUTRAL} />;
            case GAMESTATUS.FINISHED:
                return <Overlay type={OverlayType.SUCCESS} />;
        }
    };

    return (
        <div
            className={
                styles[
                    `${overlayType === 'unlock' ? 'neutral' : overlayType}Container`
                ]
            }
        >
            <CloudsBackground overlayType={overlayType} />
            {handleRender()}
        </div>
    );
};

export default Tv;
