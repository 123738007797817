import {
    cloudsBottomBackground,
    cloudsTopBackground,
} from '@/assets/images/tablets/clouds';
import globalStyle from '@/assets/styles/app.module.scss';
import dataGlobal from '@/data/global.json';
import ActionButton from '@/features/devices/tablets/components/actionButton/ActionButton';
import CardContainerTablet from '@/features/devices/tablets/components/card/CardContainer';
import Header from '@/features/devices/tablets/components/header/Header';
import TabletScreen from '@/features/devices/tablets/components/screens/TabletScreen';
import tabletStyle from '@/features/devices/tablets/components/screens/tabletScreen.module.scss';
import VoteContainer from '@/features/devices/tablets/components/vote/VoteContainer';
import useCurrentOverlay from '@/hooks/useCurrentOverlay';
import useCurrentTitleHeaderTablet from '@/hooks/useCurrentTitleHeaderTablet';
import { useItemsSelected } from '@/hooks/useItemsSelected';
import useReSynchronizeDevice from '@/hooks/useReSynchronizeDevice';
import { emitSocketEvent } from '@/services/global/globalUtils';
import { useDeviceStore } from '@/store/deviceStore';
import { useGameStore } from '@/store/gameStore';
import { useEffect } from 'react';
import {
    GAMESTATUS,
    type GameStatusActionWithoutGame4,
} from '@/types/global/types';

const deviceColor = 'red';

const RedActionSwitch: React.FC = () => {
    const { red } = useDeviceStore.use.deviceStatus();
    const currentGameStatus = useGameStore.use.gameStatus();
    const currentHeaderTitle = useCurrentTitleHeaderTablet(deviceColor);
    const { isCurrentOverlay, overlayType } = useCurrentOverlay();
    const isThresholdReached = useGameStore.use.isThresholdReached();
    const itemsSelected = useItemsSelected(
        currentGameStatus as GameStatusActionWithoutGame4
    );

    useReSynchronizeDevice('red');

    useEffect(() => {
        document.title = `🔴 ${red.label}`;
        emitSocketEvent('send_synchronize_device', {
            deviceColor: 'red',
        });
    }, [red.label]);

    const isTabletInactive = !red.isActivated;

    const handleRender = () => {
        switch (currentGameStatus) {
            case GAMESTATUS.GAME1PREVENTION:
            case GAMESTATUS.GAME2PREVENTION:
                return (
                    <ActionButton
                        title={dataGlobal.buttons.next}
                        action='goToNextOverlay'
                        color='red'
                    />
                );
            case GAMESTATUS.GAME1ACTION:
                if (isCurrentOverlay) {
                    return (
                        <ActionButton
                            title={
                                overlayType === 'success' || isThresholdReached
                                    ? 'Suivant'
                                    : 'Réessayer'
                            }
                            action='validation'
                            color='red'
                        />
                    );
                }
                return (
                    <CardContainerTablet deviceColor={deviceColor} type='risks' />
                );
            case GAMESTATUS.GAME2ACTION:
                return (
                    <CardContainerTablet
                        type={'pathways'}
                        deviceColor={deviceColor}
                    />
                );
            case GAMESTATUS.GAME3ACTION:
                if (!itemsSelected?.environments) {
                    return null;
                }
                return isCurrentOverlay ? (
                    <ActionButton
                        title={overlayType === 'success' ? 'Suivant' : 'Réessayer'}
                        action='validation'
                        color='red'
                    />
                ) : (
                    <CardContainerTablet
                        deviceColor={deviceColor}
                        type='diseases'
                    />
                );
            case GAMESTATUS.GAME4ACTION:
                return <VoteContainer color='red' />;
            case GAMESTATUS.FINISHED:
                break;
        }
    };

    return (
        <div className={globalStyle.containerTablet}>
            {isTabletInactive ? (
                <TabletScreen color='red' />
            ) : (
                <div className={tabletStyle.redTabletContainer}>
                    <img
                        draggable={false}
                        src={cloudsTopBackground.red.big}
                        alt='Big clouds top'
                    />
                    <img
                        src={cloudsTopBackground.red.small}
                        alt='Small clouds top'
                    />

                    {currentHeaderTitle && <Header title={currentHeaderTitle} />}

                    {handleRender()}
                    <img
                        draggable={false}
                        src={cloudsBottomBackground.red}
                        alt='Clouds bottom'
                    />
                </div>
            )}
        </div>
    );
};

export default RedActionSwitch;
