import { Lose, Win } from '@/assets/sounds/soundsEffect';
import MainComponent from '@/features/devices/tv/components/mainComponent/MainComponent';
import Banner from '@/features/devices/tv/components/overlay/Banner';
import styles from '@/features/devices/tv/components/overlay/overlay.module.scss';
import AudioComponent from '@/features/reusablecomponents/global/AudioComponent';
import TextAnimation from '@/features/reusablecomponents/text/TextAnimation';
import useCurrentOverlay from '@/hooks/useCurrentOverlay';
import { playSound } from '@/services/global/globalUtils';
import { useGameStore } from '@/store/gameStore';
import { useOverlayStore } from '@/store/overlayStore';
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { OverlayType } from '@/types/global/types';

interface OverlayProps {
    type?: OverlayType;
}

const overlayVariants = {
    initial: {
        y: 1000,
    },
    animate: {
        y: 0,
    },
};

const Overlay: React.FC<OverlayProps> = ({ type = OverlayType.NEUTRAL }) => {
    const currentOverlayDescriptions = useOverlayStore.use.overlayDescriptions();
    const currentOverlayIndex = useOverlayStore.use.currentOverlayIndex();
    const currentTextAudio = currentOverlayDescriptions[currentOverlayIndex]?.audio;
    const isThresholdReached = useGameStore.use.isThresholdReached();

    const currentText = currentOverlayDescriptions[currentOverlayIndex]?.text;
    const currentCharacter =
        currentOverlayDescriptions[currentOverlayIndex]?.character;

    const [characterImage, setCharacterImage] = useState<string | null>(null);

    useEffect(() => {
        if (currentCharacter) {
            import(
                `@/assets/images/characters/${currentCharacter.toLowerCase()}.png`
            )
                .then((image) => setCharacterImage(image.default))
                .catch(() => setCharacterImage(null));
        }
    }, [currentCharacter]);

    const currentJob = currentOverlayDescriptions[currentOverlayIndex]?.job;
    const { currentTitle } = useCurrentOverlay();
    const errorOrSuccess = type === 'success' || type === 'error';

    useEffect(() => {
        const handleAssets = (): {
            audio: string;
        } => {
            switch (type) {
                case 'neutral':
                    return {
                        audio: '',
                    };
                case 'success':
                    return {
                        audio: Win,
                    };
                case 'error':
                    return {
                        audio: Lose,
                    };
                default:
                    return {
                        audio: '',
                    };
            }
        };
        playSound(handleAssets().audio);
    }, [type]);

    return (
        <motion.div
            className={`${styles.containerOverlayTv} ${isThresholdReached ? styles.threshold : undefined}`}
            variants={overlayVariants}
            initial='initial'
            animate='animate'
            transition={{ type: 'spring', duration: 1 }}
        >
            {currentTextAudio && <AudioComponent src={currentTextAudio} />}
            {errorOrSuccess && <Banner />}
            <div className={styles.textContainer}>
                {type === 'unlock' ? (
                    <h1>
                        {currentTitle} {currentText}
                    </h1>
                ) : (
                    <>
                        <h1>
                            {currentCharacter
                                ? currentCharacter
                                : type === 'neutral' && currentTitle}
                            {currentJob && `, ${currentJob}`}
                        </h1>
                        <TextAnimation text={currentText} />
                    </>
                )}
                {isThresholdReached && <MainComponent />}
            </div>
        </motion.div>
    );
};

export default Overlay;
