import actionData from '@/data/actionDatas.json';
import goodCombinationsData from '@/data/goodCombinations.json';
import overlayContent from '@/data/overlayDeviceContent.json';
import {
    handleSendGameStatus,
    handleTabletStatus,
    handleUpdateOverlayDescriptions,
} from '@/services/global/globalHandleFunctions';
import {
    emitSocketEvent,
    getNextGameStatus,
    tabletStatusConfigAtValidation,
    tabletStatusGameConfig,
} from '@/services/global/globalUtils';
import type { Game4PlayersAnswer } from '@/types/games/game4/types';
import {
    GAMESTATUS,
    OverlayType,
    type OverlayDescription,
} from '@/types/global/types';

export const validateGame4Action = (
    playersAnswer: Game4PlayersAnswer,
    situationIndex: number
) => {
    const currentGoodCombination = goodCombinationsData.GAME4ACTION[situationIndex];
    const currentSituation = actionData.GAME4ACTION.situations[situationIndex];

    const isGoodCombination = Object.values(playersAnswer).every(
        (answer) => answer === currentGoodCombination
    );

    const message = isGoodCombination
        ? (currentSituation.successMessage as OverlayDescription[])
        : (currentSituation.errorMessage as OverlayDescription[]);

    const type = isGoodCombination ? OverlayType.SUCCESS : OverlayType.ERROR;

    handleUpdateOverlayDescriptions(message, type);
    handleTabletStatus(tabletStatusConfigAtValidation[GAMESTATUS.GAME4ACTION]);
};

export const handleGame4Validation = (currentSituationIndex: number) => {
    const numberOfSituations = actionData.GAME4ACTION.situations.length;
    const nextGameStatus = getNextGameStatus();

    if (currentSituationIndex === numberOfSituations - 1) {
        handleSendGameStatus(nextGameStatus);
        handleTabletStatus(
            tabletStatusGameConfig[
                nextGameStatus as keyof typeof tabletStatusGameConfig
            ]
        );
        handleUpdateOverlayDescriptions(
            overlayContent[nextGameStatus].tv,
            OverlayType.NEUTRAL
        );
        return;
    }

    handleResetItems4();
    handleTabletStatus(tabletStatusGameConfig[GAMESTATUS.GAME4ACTION]);
    handleUpdateOverlayDescriptions([], OverlayType.NEUTRAL);

    handleUpdateSituationIndexGame4(currentSituationIndex + 1);
};

export const handleResetItems4 = () => {
    emitSocketEvent('send_reset_items_game_4', {});
};

export const handleUpdateSituationIndexGame4 = (index: number) => {
    emitSocketEvent('send_update_current_situation_index_game_4', {
        currentSituationIndex: index,
    });
};
