import { arraysEqual } from '@/services/global/globalUtils';
import goodCombinationsData from '@/data/goodCombinations.json';
import type { Game1ItemsSelected } from '@/types/games/game1/types';
import { GAMESTATUS, type Message, OverlayType } from '@/types/global/types';

export const validateGame1Action = (
    itemsSelected: Game1ItemsSelected
): { message?: Message[]; type: OverlayType } => {
    const goodCombinations = goodCombinationsData[GAMESTATUS.GAME1ACTION];

    const selectedProduct = itemsSelected.products;

    // Filter undefined values and sort arays
    const selectedTypeIds = itemsSelected.types
        .filter(Boolean)
        .map((item) => item.id)
        .sort();

    const selectedRiskIds = itemsSelected.risks
        .filter(Boolean)
        .map((item) => item.id)
        .sort();

    const isValidated = goodCombinations.some(
        (combination) =>
            selectedProduct.id === combination.productsId &&
            arraysEqual(combination.typesId.sort(), selectedTypeIds) &&
            arraysEqual(combination.risksId.sort(), selectedRiskIds)
    );

    return {
        message: isValidated
            ? selectedProduct.successMessage
            : selectedProduct.errorMessage,
        type: isValidated ? OverlayType.SUCCESS : OverlayType.ERROR,
    };
};
